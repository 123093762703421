<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/marketplaces')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("каспи_магазин") }}
      v-spacer
      template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && !kaspiShopConnected")
        v-btn(:disabled="loading" outlined @click="preConnect()") {{ $t("подключить") }}
    v-progress-linear(:active="loading" :indeterminate="loading" fixed top color="secondary")

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/kaspi-shop-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .subtitle-1.my-2 {{ $t("продавайте_через_каспи_магазин_чек_пробьется_автоматически") }}

      v-row
        v-col.pt-0
          v-list.re-v-list-fulltext.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-receipt-text-check-outline
              v-list-item-content
                v-list-item-title {{ $t("не_бойтесь_пропустить_чек") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-apps
              v-list-item-content
                v-list-item-title {{ $t("не_нужно_переключаться_между_приложениями") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-printer-pos-check
              v-list-item-content
                v-list-item-title {{ $t("чек_пробивается_автоматически_после_выдачи_заказа") }}

      v-row
        v-col
          .subtitle-1 {{ $t("для_подключения_у_вас_должен_быть_как_минимум_один_заказ") }}. &nbsp;
          span.subtitle-1.secondary--text.text-uppercase(@click="$openLink(locale === 'ru' ? 'https://help.rekassa.kz/ru/re-kassa-3-0/podkliuchieniie-avtomatichieskoi-fiskalizatsii-v-kaspi-maghazinie' : 'https://help.rekassa.kz/ru/kz/kaspi-dukienindie-avtomatty-fiskalizatsiiany-k-osu', '_system')") {{ $t("подробнее") }}...

      v-row(v-if="cashRegister.status === 'REGISTERED'")
        v-col
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("статус") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ kaspiShopConnected ? $t("подключено") : $t("не_подключено") }}
            v-list-item(v-if="kaspiShopConnected && kaspiShopAddress")
              v-list-item-content
                v-list-item-title {{ $t("торговая_точка") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ kaspiShopAddress }}
            v-list-item(v-if="kaspiShopConnected && kaspiShopConnectDate")
              v-list-item-content
                v-list-item-title {{ $t("дата_подключения") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ kaspiShopConnectDate | moment("DD-MM-YYYY HH:mm:ss") }}
            v-list-item(v-if="!kaspiShopConnected && kaspiShopDisconnectDate")
              v-list-item-content
                v-list-item-title {{ $t("дата_отключения") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ kaspiShopDisconnectDate | moment("DD-MM-YYYY HH:mm:ss") }}

            div.px-4(v-if="user._links.createCashRegister")
              v-btn.my-3(v-if="!kaspiShopConnected" color="secondary" block :disabled="loading" @click="preConnect()") {{ $t("подключить") }}
              v-btn.my-3(v-else color="error" block :disabled="loading" @click="showDisconnectPinpad()") {{ $t("отключить") }}

  re-pinpad(v-model="connectDialog" :title="$t('подключение_name', { name: 'Каспи Магазин'})" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_name', { name: 'Каспи Магазин'})" :loading="loading" :errorText.sync="connectErrorMessage" @action="connect")
  re-pinpad(v-model="disconnectDialog" :title="$t('отключение_name', { name: 'Каспи Магазин'})" :subtitle="$t('введите_пин-код')" :text="$t('для_отключения_name', { name: 'Каспи Магазин'})" :loading="loading" :errorText.sync="disconnectErrorMessage" @action="disconnect")

  v-dialog(v-model="wizardDialog" :fullscreen="$vuetify.breakpoint.xsOnly" persistent max-width="400")
    v-stepper.re-stepper(v-model='step' flat)

      v-stepper-header
        v-card-title.pa-3
          v-btn(icon @click="stepBack()" :disabled="step !== 2")
            v-icon(large) mdi-menu-left
          v-spacer
          v-row(style="width: 100px;")
            v-col.px-1
              v-progress-linear.rounded(:value="firstProgress" height="6")
            v-col.px-1
              v-progress-linear.rounded(:value="step > 1 ? 100 : 0" height="6")
            v-col.px-1
              v-progress-linear.rounded(:value="step > 2 ? 100 : 0" height="6")
          v-spacer
          v-btn.ml-1(icon @click="closeWizard()" :disabled="loading")
            v-icon mdi-close

      v-stepper-items.mb-5

        v-stepper-content(step='1')
          div.re-stepper-content
            v-card.mb-3
              h1 {{ $t("введите_token_api") }}
              .subtitle-1.pt-4.pb-2(style="line-height: 1.4;") {{ $t("токен_api_и_id_партнера_используется_для_доступа_к_вашим_данным_в_каспи_магазине") }}
              span.subtitle-1.secondary--text(@click="$openLink(locale === 'ru' ? 'https://help.rekassa.kz/ru/re-kassa-3-0/podkliuchieniie-avtomatichieskoi-fiskalizatsii-v-kaspi-maghazinie' : 'https://help.rekassa.kz/ru/kz/kaspi-dukienindie-avtomatty-fiskalizatsiiany-k-osu', '_system')") {{ $t("подробнее") }}...
              v-form.mt-6(ref="tokenForm" @submit.prevent="showConnectPinpad()")
                v-text-field.pb-2(label="Token API" v-model="tokenInput" type="text" :rules="[rules.required, (tokenInput ? rules.length(44) : false)]" @input="tokenInputError = null; merchantUidInputError = null" :error-messages="tokenInputError" autocomplete="off" maxlength="44" dense)
                v-text-field.pb-2(:label="$t('id_партнера')" v-model="merchantUidInput" :rules="[rules.minLength(3, merchantUidInput)]" type="tel" @input="merchantUidInputError = null; tokenInputError = null" :error-messages="merchantUidInputError" autocomplete="off" dense)
            v-btn.rounded-xl(block color="secondary" @click="showConnectPinpad()") {{ $t("далее") }}

        v-stepper-content(step='2')
          div.re-stepper-content
            v-card.mb-3
              h1 {{ $t("выберите_торговую_точку") }}
              .subtitle-1.pt-4.pb-2(style="line-height: 1.4;" v-dompurify-html="$t('вы_подключаете_кассу_бин_иин_к_услуге_автоматической_фискализации', { businessName: organization.businessName, businessId: organization.businessId, serialNumber: cashRegister.serialNumber })")
              template(v-if="!(tradePointListProgress === 100 && tradePointList.length === 0)")
                .text-center.py-4.subtitle-2.blinker(v-if="tradePointListProgress !== 100") {{ $t("загружаем_все_ваши_торговые_точки") }}... {{ tradePointListProgress }}%
                .div(v-else style="height: 54px")
                v-form(ref="tradePointForm" v-if="tradePointList.length > 0")
                  v-radio-group.re-radio-group(v-model="tradePointSelect")
                    v-radio.mb-6(v-for="(item, $index) in tradePointList" :key="'tp_' + $index" :label="item.status === 'FREE' ? item.address : `${item.address}; ⛔️ ${$t('подключена_к_кассе')}: ${item.cashRegisterSerialNumber}`" :value="item" :disabled="item.status !== 'FREE'")
                v-btn.rounded(block color="primary" @click="selectTerminal()" :disabled="tradePointSelect === null" :loading="loading") {{ $t("подключить") }}

              template(v-else)
                div.d-flex.justify-center.align-center.pt-8.pb-4
                  v-icon(size="50") mdi-alert-circle
                h3.text-center {{ $t("мы_не_нашли_торговых_точек") }}
                .text-center.subtutle-1.grey--text.px-6.py-3 {{ $t("убедитесь_что_за_последние_две_недели_в_торговой_точке_был_совершен_хотя_бы_один_заказ_и_что_на_она_уже_не_подключена_к_другой_кассе") }}
                v-btn.rounded.mt-4(block color="primary" @click="showChat()") {{ $t("служба_поддержки") }}

        v-stepper-content(step='3')
          img(:src="`/static/kaspi-shop-banner-${locale}.png`" style="width: 100%")
          div.re-stepper-content
            v-card.mb-3
              h1 {{ $t("готово") }}!
              .subtitle-1.pt-4.pb-2(style="line-height: 1.4;") {{ $t("вы_подключили_авто_фискализацию_чеков_каспи_магазина") }}
              .subtitle-1.pt-4.pb-4(style="line-height: 1.4;") {{ $t("теперь_все_чеки_за_проданные_товары_будут_формироваться_автоматически_после_доставки_заказа_и_будут_храниться_в_разделе_продажи") }}
            v-btn.rounded.mb-6(block color="primary" @click="$router.push('/kkm')") {{ $t("к_продажам") }}
            v-btn.rounded(block color="secondary" @click="$router.push('/')") {{ $t("на_главную") }}

</template>
<script>
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
  },

  data: () => ({
    firstProgress: 0,
    step: 1,
    loading: false,
    wizardDialog: false,

    tokenInput: null,
    tokenInputError: null,
    merchantUidInput: null,
    merchantUidInputError: null,

    connectDialog: false,
    connectErrorMessage: null,
    disconnectDialog: false,
    disconnectErrorMessage: null,

    terminalId: null,
    tradePointList: [],
    tradePointListProgress: 0,
    tradePointSelect: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },

    kaspiShopConnected() {
      return this.configuration.kaspiShop && this.configuration.kaspiShop.connected
    },

    kaspiShopAddress() {
      return this.configuration.kaspiShop && this.configuration.kaspiShop.address
    },

    kaspiShopConnectDate() {
      return this.configuration.kaspiShop && this.configuration.kaspiShop.connectDate
    },

    kaspiShopDisconnectDate() {
      return this.configuration.kaspiShop && this.configuration.kaspiShop.disconnectDate
    },
  },

  methods: {
    ...mapActions({
      listTerminalTradepoints: 'kaspiShop/listTerminalTradepoints',
      listTerminalTradepointsStatus: 'kaspiShop/listTerminalTradepointsStatus',
      closeTerminalTradepointsStatus: 'kaspiShop/closeTerminalTradepointsStatus',
      registerTerminalTradepoint: 'kaspiShop/registerTerminalTradepoint',
      deregisterTerminalTradepoint: 'kaspiShop/deregisterTerminalTradepoint',
      saveConfiguration: 'cashRegisters/saveConfiguration',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    stepBack() {
      this.step = this.step > 2 ? this.step - 1 : 1
      this.closeTerminalTradepointsStatus()
    },

    closeWizard() {
      this.wizardDialog = false
      this.closeTerminalTradepointsStatus()
    },

    isShiftClosed() {
      if (this.cashRegister.shiftOpen) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return false
      }
      return true
    },

    checkCashRegister() {
      if (this.cashRegister.fdoMode !== 'ONLINE') {
        this.showSnackbar({ message: this.$t('чтобы_подключить_интеграцию_касса_должна_быть_в_режиме_онлайн_проверьте_произведена_ли_оплата_за_офд_или_обратитесь_в_службу_поддержки') })
        return false
      }
      return true
    },

    getModifiedConfiguration(modifiedConfiguration) {
      return {
        ...this.configuration,
        kaspiShop: modifiedConfiguration,
      }
    },

    preConnect() {
      if (this.isShiftClosed() && this.checkCashRegister()) {
        this.tokenInput = null
        this.merchantUidInput = null
        this.tradePointList = []
        this.tradePointListProgress = 0
        this.terminalId = null
        this.tradePointSelect = null
        this.step = 1
        this.firstProgress = 0
        this.wizardDialog = true
        this.$nextTick(() => {
          setTimeout(() => { this.firstProgress = 100 }, 200)
        })
      }
    },

    showConnectPinpad() {
      if (this.$refs.tokenForm.validate()) {
        this.connectDialog = true
      }
    },

    showDisconnectPinpad() {
      if (this.isShiftClosed()) {
        this.disconnectDialog = true
      }
    },

    connect(pincode) {
      this.loading = true
      this.listTerminalTradepoints({
        data: {
          cashRegisterId: this.cashRegister.id,
          cashRegisterSerialNumber: this.cashRegister.serialNumber,
          businessId: this.organization.businessId,
          kaspiShopToken: this.tokenInput,
          merchantUid: this.merchantUidInput,
        },
        password: pincode,
      }).then((result) => {
        this.terminalId = result.data
        this.connectDialog = false
        this.tradePointList = []
        this.tradePointListProgress = 0
        this.step = 2
        this.loading = false
        this.closeTerminalTradepointsStatus()
        this.fetchTerminalTradepointsStatus()
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.connectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else if (error && error.response && error.response.data && error.response.data.code === 'WRONG_TOKEN') {
          this.loading = false
          this.connectDialog = false
          this.tokenInputError = this.$t('kaspiShop/WRONG_TOKEN')
          this.merchantUidInputError = this.$t('невереный_id_партнера')
        } else {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    fetchTerminalTradepointsStatus() {
      this.listTerminalTradepointsStatus({
        terminalId: this.terminalId,
        messageCallback: (event) => this.processTerminalTradepointsStatus(event),
        errorCallback: (event) => this.processTerminalTradepointsStatusError(event),
      })
    },

    processTerminalTradepointsStatus(event) {
      if (event && event.data) {
        const data = JSON.parse(event.data)
        if (data.id === 'percentage') {
          this.tradePointListProgress = data.data
          if (data.data === 100) {
            this.closeTerminalTradepointsStatus()
            this.loading = false
          }
        } else if (data.id === 'tradePoint') {
          this.tradePointList.push(data.data)
        }
      }
    },

    processTerminalTradepointsStatusError() {
      this.closeTerminalTradepointsStatus()
    },

    selectTerminal() {
      this.closeTerminalTradepointsStatus()
      this.tradePointListProgress = 100
      this.loading = true
      this.tradePointSelect.merchantUid = this.merchantUidInput
      this.registerTerminalTradepoint({
        data: this.tradePointSelect,
        terminalId: this.terminalId,
      }).then((result) => {
        this.saveConfiguration({
          cashRegister: this.cashRegister,
          configuration: this.getModifiedConfiguration({ connected: true, address: result.data.tradePointModel.address, connectDate: result.data.registrationDate }),
          password: result.data.userPassword,
        }).then(() => {
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.step = 3
            this.loading = false
            this.analyticsLogEvent({ eventName: 're_kaspi_shop_connected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
          })
        }).catch((error) => {
          this.wizardDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
          console.debug(error)
        })
      }).catch((error) => {
        this.wizardDialog = false
        this.loading = false
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
        console.debug(error)
      })
    },

    disconnect(pincode) {
      this.loading = true
      this.deregisterTerminalTradepoint({
        data: {
          cashRegisterId: this.cashRegister.id,
        },
        password: pincode,
      }).then((result) => {
        this.saveConfiguration({
          cashRegister: this.cashRegister,
          configuration: this.getModifiedConfiguration({ connected: false, disconnectDate: result.data.deregistrationDate }),
          password: result.data.userPassword,
        }).then(() => {
          this.selectCashRegister(this.cashRegister.id).then(() => {
            this.disconnectDialog = false
            this.loading = false
            this.showSnackbar({ message: this.$t('успешно_отключен_name', { name: 'Каспи Магазин' }) })
            this.analyticsLogEvent({ eventName: 're_kaspi_shop_disconnected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
          })
        }).catch((error) => {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
          console.debug(error)
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.disconnectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
          console.debug(error)
        }
      })
    },

    showChat() {
      this.closeWizard()
      if (!this.$isCordova()) {
        window.HelpCrunch('showChatWidget')
        window.HelpCrunch('openChat')
      } else {
        window.cordova.plugins.HelpCrunchPlugin.showChatScreen(() => {}, () => {})
      }
      this.analyticsLogEvent({ eventName: 're_nav_helpcrunch_button_click' })
    },
  },
}
</script>

<style lang="stylus">
.re-stepper
  height 100%
  .v-stepper__header
    height 54px
    -webkit-box-shadow none !important
    box-shadow none !important
    .v-card__title
      width 100%
  .v-stepper__content
    padding 0 !important
  .re-stepper-content
    padding 24px 24px 16px 24px
  .v-radio
    animation slideDownFadeIn 0.5s ease-out forwards

@keyframes slideDownFadeIn {
    from {
        max-height 0
        opacity 0
    }
    to {
        max-height 200px
        opacity 1
    }
}
</style>

<template lang="pug">
  v-container(:class="[$vuetify.breakpoint.smAndUp ? 'px-9' : 'px-3']")
    v-row(justify="space-around")
      v-col.pb-0(cols="12" sm="5")

        v-subheader {{ $t("позиции") }}

        v-row
          v-col.pt-0.pb-3(cols="6")
            v-btn(height="40" color="reForeground" @click="createNewItem()") + {{ $t("добавить") }}
          v-col.pt-0.pb-3(cols="6" align="right")
            v-text-field.rounded-xl(v-if="!$isCordova()" ref="barcode" v-model="barcode" :placeholder="$t('штрих_код')" @keydown.enter="searchByBarcode()" flat filled dense autocomplete="off" clearable hide-details append-outer-icon="mdi-magnify" :loading="positionsLoading" :disabled="positionsLoading" @click:append-outer="searchByBarcode()")
            v-btn(v-else height="40" color="reForeground" :loading="positionsLoading" @click="fetchPositionsBefore()") + {{ $t("сканировать") }}
          v-col.pt-3.pb-1(cols="12" v-if="!$isCordova()")
            v-autocomplete.rounded-xl(v-model="autocompleteSelect" ref="autocomplete" :search-input.sync="autocompleteSearch" :placeholder="$t('наименование')" :items="autocompleteItems" item-text="name" return-object filled flat clearable hide-details :hide-no-data="!autocompleteSearch" :allow-overflow="false" :loading="positionsLoading" :disabled="positionsLoading"  @change="autocompleteChanged")
              template(slot="item" slot-scope="{ item }")
                v-list-item-content
                  v-list-item-title(v-dompurify-html="item.name")
                  v-list-item-subtitle {{ item.price | numeral('0,0.[00]') }} ₸ ({{ $t('unitType.' + item.unitType) }})

        v-subheader(v-if="items.length > 0" style="justify-content: space-between") {{ $t("список") }}
          a(@click.prevent="clear()") Очистить
        v-list.py-0.re-v-list-fulltext(two-line)
          transition-group(name="list" tag="p")
            v-list-item(v-for="(item, $index) in items" :key="'i_' + $index" @click="editItem($index)" transition="slide-y-transition")
              v-list-item-content
                v-list-item-title {{ $index + 1 }}. {{ preferences.barcodeInPositions && item.commodity.barcode ? item.commodity.barcode : '' }} {{ item.commodity.name }}
                v-list-item-subtitle {{ item.commodity.realQuantity | numeral('0,0.[000]') }} {{ $t('unitType.' + item.commodity.unitType) }} &times; {{ item.commodity.price.value | numeral('0,0.[00]') }} ₸
                  | {{ item.commodity.discountValue ? ` - ${$options.filters.numeral(item.commodity.discountValue, '0,0.[00]')} ₸ (${$t('скидка')}${item.commodity.discountType === 'PERCENTAGE' ? ` ${item.commodity.discountPercentage}%` : ''}) ` : '' }}
                  | {{ item.commodity.markupValue ? ` + ${$options.filters.numeral(item.commodity.markupValue, '0,0.[00]')} ₸ (${$t('наценка')}${item.commodity.markupType === 'PERCENTAGE' ? ` ${item.commodity.markupPercentage}%` : ''}) ` : '' }}
              v-list-item-action
                v-list-item-action-text.headline {{ item.commodity.total | numeral('0,0.[00]') }} ₸
                v-list-item-action-text(v-if="item.commodity.taxes && item.commodity.taxes[0]") {{ item.commodity.taxes[0].sum.value | numeral('0,0.[00]') }} ₸ ({{ $t('в_т_ч_ндс') }} {{ item.commodity.taxes[0].percent / 1000 }}%)

      v-col.pt-0.pt-sm-3(cols="12" sm="5")
        v-subheader {{ preferences.customerIinBin ? $t("дополнительно") : $t("скидка_наценка") }}
        v-list.py-0
          v-list-item(@click="editDiscountMarkup()")
            v-list-item-content
              v-list-item-title {{ $t("discountMarkupType." + discountMarkup.type + "_short") }}
            v-list-item-action
              v-list-item-action-text.headline {{ discountMarkup.valueOrPecentage > 0 ? (discountMarkup.type.includes('DISCOUNT') ? '-' : '+') : ''}} {{ discountMarkup.valueOrPecentage }} {{ dictionary.discountMarkupType[discountMarkup.type].suffix }}
          v-list-item(v-if="preferences.customerIinBin" @click="editСustomerIinBin()")
            v-list-item-content
              v-list-item-title {{ $t("иин_бин") }}
            v-list-item-action
              v-list-item-action-text.subtitle-1 {{ customerIinBin }}

        v-subheader {{ $t("оплата") }}
        v-list.payments.py-0
          v-list-item(v-for="(payment, $index) in payments" :key="'i_' + $index" @click="fillPayment($event, payment)")
            v-list-item-action
              v-icon.no-focus(id="selectPayment" @click="selectPayment($event, payment)") mdi-pencil
            v-list-item-content
              v-list-item-title {{ $t("paymentType." + payment.type) }}
              v-list-item-subtitle.caption {{ $t("paymentType." + payment.type + "_tip") }}
            v-list-item-action
              v-list-item-action-text.headline {{ payment.sum | numeral('0,0.[00]') }} ₸

        v-subheader {{ $t("итого") }}
        v-list.py-0.no-color-background-list
          v-list-item
            v-list-item-content
              v-list-item-title {{ $t("к_оплате") }}
            v-list-item-action
              v-list-item-action-text.headline {{ totalSum | numeral('0,0.[00]') }} ₸
          v-list-item(v-if="longScreen")
            v-list-item-content
              v-list-item-title {{ $t("принято") }}
            v-list-item-action
              v-list-item-action-text.headline {{ paymentsSum | numeral('0,0.[00]') }} ₸
          v-list-item(v-if="longScreen")
            v-list-item-content
              v-list-item-title {{ $t("сдача") }}
            v-list-item-action
              v-list-item-action-text.headline {{ changeCash > 0 ? '-' : '' }} {{ changeCash | numeral('0,0.[00]') }} ₸

        v-row
          v-col
            v-btn(block color="secondary" @click="prepareTicketRequest()") {{ $t("далее") }}

    re-item(v-model="itemDialog" :item="editableItem" :preNewItem="preNewItem" @addItem="addItem" @saveItem="saveItem" @deleteItem="deleteItem" v-if="itemDialogRecreate")

    re-payment(v-model="paymentDialog" :payment="selectedPayment" :paymentsSum="paymentsSum" :total="totalSum" @savePayment="savePayment" v-if="paymentDialogRecreate")

    re-discount-markup(v-model="discountMarkupDialog" :discountMarkup="selectedDiscountMarkup" :total="totalSumWithoutDiscountMarkup" @saveDiscountMarkup="saveDiscountMarkup" v-if="discountMarkupDialogRecreate")

    re-customer-iin-bin(v-model="customerIinBinDialog" :customerIinBinProp="customerIinBin" @saveCustomerIinBin="saveCustomerIinBin")

</template>
<script>
import Vue from 'vue'
import fdo from '@comrun/kfdo'
import { Decimal } from 'decimal.js'
import { mapState, mapActions } from 'vuex'
import dictionaryMixin from '../../../mixins/dictionaryMixin'
import billsAndCoinsMixin from '../../../mixins/billsAndCoinsMixin'
import TicketDetailsItem from './TicketDetailsItem.vue'
import TicketDetailsPayment from './TicketDetailsPayment.vue'
import TicketDetailsDiscountMarkup from './TicketDetailsDiscountMarkup.vue'
import TicketDetailsCustomerIinBin from './TicketDetailsCustomerIinBin.vue'

export default {
  components: {
    're-item': TicketDetailsItem,
    're-payment': TicketDetailsPayment,
    're-discount-markup': TicketDetailsDiscountMarkup,
    're-customer-iin-bin': TicketDetailsCustomerIinBin,
  },

  mixins: [dictionaryMixin, billsAndCoinsMixin],

  data: () => ({
    ticketRequest: null,

    itemDialog: false,
    itemDialogRecreate: false,
    editableItem: null,
    editableItemIndex: null,

    paymentDialog: false,
    paymentDialogRecreate: false,
    selectedPayment: null,
    payments: [
      {
        type: 'PAYMENT_CASH',
        sum: 0,
      },
      {
        type: 'PAYMENT_CARD',
        sum: 0,
      },
      {
        type: 'PAYMENT_MOBILE',
        sum: 0,
      },
    ],

    discountMarkupDialog: false,
    discountMarkupDialogRecreate: false,
    selectedDiscountMarkup: null,
    discountMarkup: {
      type: 'DISCOUNT_PERCENTAGE',
      valueOrPecentage: 0,
      sum: 0,
      auxiliary: {
        key: 'DISCOUNT',
        value: '0%',
      },
    },

    preNewItem: null,
    barcode: null,

    autocompleteSelect: null,
    autocompleteSearch: null,
    autocompleteLoading: false,
    autocompleteItems: [],

    customerIinBinDialog: false,
    customerIinBin: null,
  }),

  computed: {
    ...mapState({
      positionList: state => state.positions.list,
      positionsLoading: state => state.positions.loading,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      preferences: state => state.cashRegisters.cashRegister.cashRegister.data.preferences,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    // Items
    items() {
      return this.ticketRequest.items.map((item) => {
        item.commodity.realQuantity = new Decimal(item.commodity.quantity).dividedBy(1000).toNumber()
        item.commodity.price.value = this.getNumberFromBillsAndCoins(item.commodity.price)
        item.commodity.sum.value = this.getNumberFromBillsAndCoins(item.commodity.sum)
        item.commodity.total = item.commodity.sum.value

        // UnitType, Discount, Markup
        if (item.commodity.auxiliary) {
          // Item unitType
          const unitType = item.commodity.auxiliary.find((aux) => aux.key === 'UNIT_TYPE')
          item.commodity.unitType = unitType ? unitType.value : 'PIECE'

          // Item discount
          const discount = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT')
          if (discount) {
            item.commodity.discountType = discount.value.includes('%') ? 'PERCENTAGE' : 'VALUE'

            if (item.commodity.discountType === 'PERCENTAGE') {
              item.commodity.discountPercentage = discount.value.replace('%', '')
              item.commodity.discountValue = new Decimal(item.commodity.discountPercentage).times(item.commodity.total).dividedBy(100).toNumber()
                .toFixed(2)
            } else {
              item.commodity.discountValue = discount.value
              item.commodity.discountPercentage = new Decimal(item.commodity.discountValue).dividedBy(item.commodity.total).times(100).toNumber()
                .toFixed(2)
            }

            item.commodity.total = new Decimal(item.commodity.total).minus(item.commodity.discountValue).toNumber()
          }

          // Item markup
          const markup = item.commodity.auxiliary.find((aux) => aux.key === 'MARKUP')
          if (markup) {
            item.commodity.markupType = markup.value.includes('%') ? 'PERCENTAGE' : 'VALUE'

            if (item.commodity.markupType === 'PERCENTAGE') {
              item.commodity.markupPercentage = markup.value.replace('%', '')
              item.commodity.markupValue = new Decimal(item.commodity.markupPercentage).times(item.commodity.total).dividedBy(100).toNumber()
                .toFixed(2)
            } else {
              item.commodity.markupValue = markup.value
              item.commodity.markupPercentage = new Decimal(item.commodity.markupValue).dividedBy(item.commodity.total).times(100).toNumber()
                .toFixed(2)
            }

            item.commodity.total = new Decimal(item.commodity.total).plus(item.commodity.markupValue).toNumber()
          }
        }

        // Taxes
        if (item.commodity.taxes && item.commodity.taxes[0]) {
          item.commodity.taxes[0].sum.value = this.getNumberFromBillsAndCoins(item.commodity.taxes[0].sum)
          const discountMarkupTax = item.commodity.auxiliary.find((aux) => aux.key === 'DISCOUNT_MARKUP_TAX')
          if (discountMarkupTax) {
            item.commodity.taxes[0].sum.value = new Decimal(item.commodity.taxes[0].sum.value).plus(discountMarkupTax.value).toNumber().toFixed(2)
          }
        }

        return item
      })
    },

    totalSumWithoutDiscountMarkup() {
      return this.items.reduce((sum, item) => new Decimal(sum).plus(item.commodity.total).toNumber(), 0)
    },

    totalSum() {
      const total = this.items.reduce((sum, item) => new Decimal(sum).plus(item.commodity.total).toNumber(), 0)

      if (this.discountMarkup.type.includes('DISCOUNT')) {
        return new Decimal(total).minus(this.discountMarkup.sum).toNumber()
      }
      return new Decimal(total).plus(this.discountMarkup.sum).toNumber()
    },

    paymentsSum() {
      return this.payments.reduce((sum, payment) => new Decimal(sum).plus(payment.sum).toNumber(), 0)
    },

    takenCash() {
      return this.payments.find(payment => payment.type === 'PAYMENT_CASH').sum
    },

    changeCash() {
      const change = this.paymentsSum > 0 && this.totalSum > 0 ? new Decimal(this.paymentsSum).minus(this.totalSum).toNumber() : 0
      return change >= 0 ? change : 0
    },

    // Not reactive
    longScreen() {
      return window.innerHeight > 700
    },
  },

  watch: {
    autocompleteSearch(val) {
      val ? this.queryPositions(val) : this.autocompleteItems = []
    },
  },

  created() {
    // Restore ticketRequest or create new
    const ticketRequestFromStore = this.$store.state.cashRegisters.ticketRequest
    if (ticketRequestFromStore) {
      // Remove all except Commudity
      ticketRequestFromStore.items = ticketRequestFromStore.items.reduce((items, item) => {
        if (item.type === fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY) {
          items.push(item)
        }
        return items
      }, [])
      this.ticketRequest = ticketRequestFromStore

      // Restore payments
      const cashPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CASH)
      const cardPayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_CARD)
      const mobilePayment = this.ticketRequest.payments.find((p) => p.type === fdo.kkm.proto.PaymentTypeEnum.PAYMENT_MOBILE)
      this.payments.find((p) => p.type === 'PAYMENT_CASH').sum = cashPayment ? new Decimal(this.getNumberFromBillsAndCoins(cashPayment.sum)).plus(this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.change)).toNumber() : 0
      this.payments.find((p) => p.type === 'PAYMENT_CARD').sum = cardPayment ? this.getNumberFromBillsAndCoins(cardPayment.sum) : 0
      this.payments.find((p) => p.type === 'PAYMENT_MOBILE').sum = mobilePayment ? this.getNumberFromBillsAndCoins(mobilePayment.sum) : 0
      this.ticketRequest.payments = []

      // Restore discount
      if (this.ticketRequest.amounts.discount) {
        this.discountMarkup.sum = this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.discount.sum)
        const discount = this.ticketRequest.amounts.discount.auxiliary.find((aux) => aux.key === 'DISCOUNT')
        if (discount) {
          if (discount.value.includes('%')) {
            this.discountMarkup.type = 'DISCOUNT_PERCENTAGE'
          } else {
            this.discountMarkup.type = 'DISCOUNT_CURRENCY'
          }
          this.discountMarkup.valueOrPecentage = discount.value.replace('%', '')
          this.discountMarkup.auxiliary = discount
        }
      }

      // Restore markup
      if (this.ticketRequest.amounts.markup) {
        this.discountMarkup.sum = this.getNumberFromBillsAndCoins(this.ticketRequest.amounts.markup.sum)
        const markup = this.ticketRequest.amounts.markup.auxiliary.find((aux) => aux.key === 'MARKUP')
        if (markup) {
          if (markup.value.includes('%')) {
            this.discountMarkup.type = 'MARKUP_PERCENTAGE'
          } else {
            this.discountMarkup.type = 'MARKUP_CURRENCY'
          }
          this.discountMarkup.valueOrPecentage = markup.value.replace('%', '')
          this.discountMarkup.auxiliary = markup
        }
      }

      // Restore customerIinBin
      if (this.ticketRequest.extensionOptions && this.ticketRequest.extensionOptions.customerIinOrBin) {
        this.customerIinBin = this.ticketRequest.extensionOptions.customerIinOrBin
      }
    } else {
      this.ticketRequest = new fdo.kkm.proto.TicketRequest()
    }

    if (localStorage.getItem('rekassa.kz-tools-openScanner') === 'true' || !this.$isCordova()) {
      localStorage.setItem('rekassa.kz-tools-openScanner', 'false')
      this.fetchPositionsBefore()
    }
  },

  mounted() {
    if (!this.$isCordova()) {
      window.addEventListener('keydown', this.keyListener, false)
    }
  },

  beforeDestroy() {
    if (!this.$isCordova()) {
      window.removeEventListener('keydown', this.keyListener, false)
    }
  },

  methods: {
    ...mapActions({
      setTicketRequest: 'cashRegisters/setTicketRequest',
      fetchPositions: 'positions/fetch',
      analyticsLogEvent: 'analytics/logEvent',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
    }),

    // -------------------------------------- Autocomplete --------------------------------------
    queryPositions(v) {
      this.autocompleteItems = this.positionList.filter(item => (item.name || '').toLowerCase().indexOf((v || '').toLowerCase()) > -1)
    },

    autocompleteChanged() {
      if (this.autocompleteSelect) {
        if (this.autocompleteSelect.price > 0) {
          this.addItem(this.buildItem(this.autocompleteSelect.name, this.autocompleteSelect.price, this.autocompleteSelect.unitType, this.autocompleteSelect.barcode))
        } else {
          this.preNewItem = {
            name: this.autocompleteSelect.name,
            price: this.autocompleteSelect.price,
            unitType: this.autocompleteSelect.unitType,
            barcode: this.autocompleteSelect.barcode,
          }
          this.editableItem = null
          this.editableItemIndex = null
          this.showItemDialog()
        }

        this.$nextTick(() => {
          this.autocompleteSelect = ''
          this.autocompleteSearch = null
        })

        this.analyticsLogEvent({ eventName: 're_positions_used' })
      }
    },

    // -------------------------------------- Barcode --------------------------------------
    keyListener(event) {
      const reg = new RegExp('^[0-9\\.]$')
      if (!this.positionsLoading
            && !this.itemDialog
            && !this.paymentDialog
            && !this.discountMarkupDialog
            && !this.customerIinBinDialog
            && reg.test(event.key)
            && !this.$refs.barcode.isFocused
            && !this.$refs.autocomplete.isFocused) {
        this.$refs.barcode.focus()
      }
    },

    fetchPositionsBefore() {
      if (this.positionList.length === 0) {
        this.fetchPositions(this.cashRegister.id).then(() => {
          if (this.$isCordova()) {
            this.scanBarcode()
          }
        }).catch(() => {
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        })
      } else if (this.$isCordova()) {
        this.scanBarcode()
      }
    },

    scanBarcode() {
      const aspectRatio = parseInt(localStorage.getItem('rekassa.kz-ui-camera-aspectRatio') || 1, 10)
      window.cordova.plugins.rekassaBarcode.scan((barcode) => {
        this.searchInPositionList(barcode)
        this.analyticsLogEvent({ eventName: 're_barcode_scanned' })
      },
      () => {}, { aspectRatio })
    },

    searchInPositionList(barcode) {
      const filterList = this.positionList.filter((item) => item.barcode === `${barcode}`)
      if (filterList.length === 1) {
        if (filterList[0].price > 0) {
          this.addItem(this.buildItem(filterList[0].name, filterList[0].price, filterList[0].unitType, filterList[0].barcode))
          this.playSound('found')
        } else {
          this.preNewItem = {
            name: filterList[0].name,
            price: filterList[0].price,
            unitType: filterList[0].unitType,
            barcode: filterList[0].barcode,
          }
          this.editableItem = null
          this.editableItemIndex = null
          this.showItemDialog()
        }
      } else if (filterList.length > 1) {
        const doublicates = filterList.reduce((items, item, index) => {
          if (index === 0) {
            items = item.name
          } else {
            items = `${items}, ${item.name}`
          }
          return items
        }, [])
        this.showSnackbar({ message: this.$t('найдено_больше_одной_записи_с_данным_штрих_кодом', { items: doublicates }) })
        this.playSound('error')
      } else if (filterList.length === 0) {
        this.showSnackbar({ message: this.$t('позиция_с_данным_штрих_кодом_не_найдена'), timeout: 2000 })

        if (this.positionList.length === 0) {
          this.showConfirm({
            title: this.$t('прайс_лист_пуст_хотите_добавить_новую_запись'),
            resolveText: this.$t('добавить'),
            rejectText: this.$t('отменить'),
          }).then(() => {
            this.$router.push('/kkm/positions')
          }).catch(() => {})
        }

        this.playSound('error')
      }
    },

    searchByBarcode() {
      this.searchInPositionList(this.barcode)
      this.barcode = null
    },

    buildItem(name, price, unitType, barcode) {
      const quantity = 1
      const sum = price

      const newItem = new fdo.kkm.proto.TicketRequest.Item({
        type: fdo.kkm.proto.TicketRequest.Item.ItemTypeEnum.ITEM_TYPE_COMMODITY,
        commodity: new fdo.kkm.proto.TicketRequest.Item.Commodity({
          name,
          barcode,
          sectionCode: '1',
          quantity: new Decimal(quantity).times(1000).toNumber(),
          price: this.getBillsAndCoinsMoney(price),
          sum: this.getBillsAndCoinsMoney(new Decimal(quantity).times(sum).toNumber().toFixed(2)),
          exciseStamp: null,
          measureUnitCode: this.unitTypes.find((ut) => ut.value === unitType).code,
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: 'UNIT_TYPE',
              value: unitType,
            }),
          ],
        }),
      })

      if (this.configuration.taxMode && this.dictionary.taxType[this.preferences.defaultTaxType].rate >= 0) {
        newItem.commodity.taxes = [
          new fdo.kkm.proto.TicketRequest.Tax({
            taxType: 100,
            taxationType: this.dictionary.taxationType[this.configuration.taxationType].code,
            percent: new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).times(1000).toNumber(),
            sum: this.getBillsAndCoinsMoney(new Decimal(sum)
              .dividedBy(new Decimal(this.dictionary.taxType[this.preferences.defaultTaxType].rate).add(100))
              .times(this.dictionary.taxType[this.preferences.defaultTaxType].rate)
              .toNumber()
              .toFixed(2)),
            isInTotalSum: true,
          }),
        ]
      }

      return newItem
    },

    playSound(type) {
      if (!this.$isCordova()) {
        const audio = new Audio(`/static/sounds/${type}.wav`)
        audio.play()
      }
    },

    // -------------------------------------- Items --------------------------------------
    createNewItem() {
      this.preNewItem = null
      this.editableItem = null
      this.editableItemIndex = null
      this.showItemDialog()
    },

    editItem($index) {
      this.preNewItem = null
      this.editableItemIndex = $index
      this.editableItem = this.ticketRequest.items[$index]
      this.showItemDialog()
    },

    saveItem(item) {
      Vue.set(this.ticketRequest.items, this.editableItemIndex, item)
      this.recalculateDiscountMarkup()
    },

    addItem(item) {
      this.ticketRequest.items.push(item)
      this.recalculateDiscountMarkup()
    },

    deleteItem() {
      this.$delete(this.ticketRequest.items, this.editableItemIndex)
      this.recalculateDiscountMarkup()
    },

    showItemDialog() {
      this.itemDialogRecreate = false
      this.itemDialog = false
      this.$nextTick(() => {
        this.itemDialogRecreate = true
        this.$nextTick(() => {
          this.itemDialog = true
        })
      })
    },

    // -------------------------------------- Payments --------------------------------------
    fillPayment(event, payment) {
      if (event.target.id === 'selectPayment') return
      if (this.ticketRequest.items.length === 0) {
        this.showSnackbar({ message: this.$t('добавьте_как_минимум_одну_позицию'), timeout: 1500 })
      } else {
        this.payments.map((p) => {
          p.sum = 0
          return p
        })
        payment.sum = this.totalSum
      }
    },

    selectPayment(event, payment) {
      window.blur(event)
      if (this.ticketRequest.items.length === 0) {
        this.showSnackbar({ message: this.$t('добавьте_как_минимум_одну_позицию'), timeout: 1500 })
        return
      }

      this.selectedPayment = { ...payment }
      this.showPaymentDialog()
    },

    savePayment(payment) {
      this.payments.find((p) => p.type === payment.type).sum = payment.sum
    },

    showPaymentDialog() {
      this.paymentDialogRecreate = false
      this.paymentDialog = false
      this.$nextTick(() => {
        this.paymentDialogRecreate = true
        this.$nextTick(() => {
          this.paymentDialog = true
        })
      })
    },

    // -------------------------------------- Customer Iin Bin ------------------------------------
    editСustomerIinBin() {
      this.customerIinBinDialog = true
    },

    saveCustomerIinBin(customerIinBin) {
      this.customerIinBin = customerIinBin
    },

    // -------------------------------------- Discount/Markup -------------------------------------
    editDiscountMarkup() {
      if (this.ticketRequest.items.length === 0) {
        this.showSnackbar({ message: this.$t('добавьте_как_минимум_одну_позицию'), timeout: 1500 })
        return
      }

      this.selectedDiscountMarkup = { ...this.discountMarkup }
      this.showDiscountMarkup()
    },

    showDiscountMarkup() {
      this.discountMarkupDialogRecreate = false
      this.discountMarkupDialog = false
      this.$nextTick(() => {
        this.discountMarkupDialogRecreate = true
        this.$nextTick(() => {
          this.discountMarkupDialog = true
        })
      })
    },

    saveDiscountMarkup(discountMarkup) {
      this.discountMarkup = discountMarkup
    },

    recalculateDiscountMarkup() {
      switch (this.discountMarkup.type) {
        case 'DISCOUNT_PERCENTAGE':
          this.discountMarkup.sum = new Decimal(new Decimal(this.totalSumWithoutDiscountMarkup).dividedBy(100).times(this.discountMarkup.valueOrPecentage)).toNumber().toFixed(2)
          break
        case 'DISCOUNT_CURRENCY':
          this.discountMarkup.sum = new Decimal(this.discountMarkup.valueOrPecentage).toNumber()
          break
        case 'MARKUP_PERCENTAGE':
          this.discountMarkup.sum = new Decimal(this.totalSumWithoutDiscountMarkup).dividedBy(100).times(this.discountMarkup.valueOrPecentage).toNumber()
            .toFixed(2)
          break
        case 'MARKUP_CURRENCY':
          this.discountMarkup.sum = new Decimal(this.discountMarkup.valueOrPecentage).toNumber()
          break
        default:
          break
      }
    },
    // -------------------------------------- Ticket Request --------------------------------------
    prepareTicketRequest() {
      if (this.ticketRequest.items.length === 0) {
        this.showSnackbar({ message: this.$t('добавьте_как_минимум_одну_позицию'), timeout: 2000 })
        return
      }

      if (this.paymentsSum === 0) {
        this.showSnackbar({ message: this.$t('выберите_тип_оплаты'), timeout: 2000 })
        return
      }

      if (new Decimal(this.paymentsSum).lessThan(this.totalSum)) {
        this.showSnackbar({ message: this.$t('недостаточно_суммы_для_оплаты'), timeout: 2000 })
        return
      }

      if (this.takenCash === 0 && this.changeCash > 0) {
        this.showSnackbar({ message: this.$t('сдача_возможна_только_при_наличии_наличного_расчета'), timeout: 3000 })
        return
      }

      if (this.changeCash > this.takenCash) {
        this.showSnackbar({ message: this.$t('сумма_сдачи_не_может_быть_больше_суммы_принятой_наличности'), timeout: 3000 })
        return
      }

      this.ticketRequest.operation = fdo.kkm.proto.OperationTypeEnum.OPERATION_SELL
      this.ticketRequest.domain = new fdo.kkm.proto.TicketRequest.Domain({ type: this.dictionary.domainType[this.preferences.domainType].code })

      this.payments.forEach(p => {
        if (p.sum > 0) {
          const sum = p.type === 'PAYMENT_CASH' ? new Decimal(p.sum).minus(this.changeCash).toNumber() : p.sum
          const payment = new fdo.kkm.proto.TicketRequest.Payment({
            type: fdo.kkm.proto.PaymentTypeEnum[p.type],
            sum: this.getBillsAndCoinsMoney(sum),
          })
          this.ticketRequest.payments.push(payment)
        }
      })

      const amounts = new fdo.kkm.proto.TicketRequest.Amounts({
        total: this.getBillsAndCoinsMoney(this.totalSum),
        taken: this.getBillsAndCoinsMoney(this.takenCash),
        change: this.getBillsAndCoinsMoney(this.changeCash),
      })

      if (this.discountMarkup.sum > 0) {
        const discountMarkup = new fdo.kkm.proto.TicketRequest.Modifier({
          sum: this.getBillsAndCoinsMoney(this.discountMarkup.sum),
          auxiliary: [
            new fdo.kkm.proto.KeyValuePair({
              key: this.discountMarkup.auxiliary.key,
              value: this.discountMarkup.auxiliary.value,
            }),
          ],
        })
        if (this.discountMarkup.type.includes('DISCOUNT')) {
          amounts.discount = discountMarkup
        } else {
          amounts.markup = discountMarkup
        }
      }
      this.ticketRequest.amounts = amounts

      if (this.customerIinBin) {
        this.ticketRequest.extensionOptions = new fdo.kkm.proto.TicketRequest.ExtensionOptions({
          customerIinOrBin: this.customerIinBin,
        })
      }

      this.setTicketRequest(this.ticketRequest)
      localStorage.setItem('rekassa.kz-ticket-prepared-from', 'DETAILS')
      this.$router.push('/draft')

      this.analyticsLogEvent({ eventName: 're_positions_prepare_draft' })
    },

    clear() {
      this.showConfirm({
        title: this.$t('вы_уверены_что_хотите_очистить'), resolveText: this.$t('очистить'), rejectText: this.$t('отменить'),
      }).then(() => {
        this.setTicketRequest(null)
        this.ticketRequest = new fdo.kkm.proto.TicketRequest()
        this.discountMarkup = {
          type: 'DISCOUNT_PERCENTAGE',
          valueOrPecentage: 0,
          sum: 0,
          auxiliary: {
            key: 'DISCOUNT',
            value: '0%',
          },
        }
        this.payments = [
          {
            type: 'PAYMENT_CASH',
            sum: 0,
          },
          {
            type: 'PAYMENT_CARD',
            sum: 0,
          },
          {
            type: 'PAYMENT_MOBILE',
            sum: 0,
          },
        ]
      }).catch(() => {})
    },

    prepareToExit(to, next) {
      if (to.name !== 'TicketDraft' && this.items.length > 0) {
        this.showConfirm({
          title: this.$t('вы_уверены_что_хотите_покинуть'), resolveText: this.$t('покинуть'), rejectText: this.$t('отменить'),
        }).then(() => {
          this.setTicketRequest(null)
          next()
        }).catch(() => {
          next(false)
        })
      } else {
        next()
      }
    },
  },

  beforeRouteLeave(to, from, next) {
    this.prepareToExit(to, next)
  },
}
</script>

<style lang="stylus" scoped>
.items
  .v-list-item__title, .v-list-item__subtitle
    white-space normal!important

.payments
  .v-list-item--active::before
    opacity 0!important

.no-color-background-list.v-list
  background-color transparent!important

.no-focus.v-icon:focus::after
  opacity 0!important
</style>

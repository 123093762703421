<template lang="pug">
v-app
  v-overlay(:value="signInWithTokenLoading")
    v-progress-circular(indeterminate size="64")

  v-container.pt-6.pl-2.pr-6
    .d-flex.justify-space-between
      v-btn(icon :large="isLarge" :style="step === 'ONBOARDING' ? 'opacity: 0' : ''" :disabled="signInWithPhoneNumberLoading" @click="back()")
        v-icon mdi-arrow-left
      .d-flex.align-center
        v-btn.plain-btn.mr-8.text-body-3.text-md-h3(@click="callCenter()")
          v-icon(:size="isLarge ? 30 : 20") mdi-phone
        v-btn.plain-btn.mr-4.mr-md-6.text-body-3.text-md-h3(@click="switchLocale('kk')" :disabled="locale === 'kk'") Қаз
        v-btn.plain-btn.text-body-3.text-md-h3(@click="switchLocale('ru')" :disabled="locale === 'ru'") Рус

  v-main

    v-container(fill-height).max-width
      v-img.left-image.d-none.d-md-block(src="/static/icons/left-shape.svg")

      // --------------------------------------------------------------------------------------------
      template(v-if="step === 'ONBOARDING'")
        .d-flex.flex-column.justify-space-between.fill-height
          v-window(v-model="onboardingStep")
            v-window-item(v-for="(slide, index) in onboardingSlides" :key="`card-${index}`" eager)
              img(:src="`/static/${slide.image}.png`" style="max-width: 400px; width: 100%;")
              p(v-dompurify-html="slide.title").font-weight-bold.text-h3.text-center.mb-5
              p(v-dompurify-html="slide.text").text-subtitle-1.text-center
          v-card-actions.justify-space-between.pb-10
            v-btn(color="reForeground" :disabled="onboardingStep === 0" min-width="96" elevation="0" @click="onboardingPrev").font-weight-bold.text-subtitle-1 {{ $t("назад") }}
            v-item-group(v-model="onboardingStep" mandatory).text-center
              v-item(v-for="n in onboardingSlides.length" :key="`btn-${n}`" v-slot="{ active, toggle }")
                v-btn(:input-value="active" icon width="16" height="16" :color="active ? 'primary' : 'reBackground darken-1'" @click="toggle")
                  v-icon mdi-circle-medium
            v-btn(color="primary" min-width="96" elevation="0" @click="onboardingNext").font-weight-bold.text-subtitle-1 {{ $t("далее") }}

      // --------------------------------------------------------------------------------------------
      template(v-if="step === 'ENTER_PHONE_NUMBER'")
        .d-flex.flex-column.align-self-start
          v-form(@submit.prevent="signIn()")
            p.text-h3.font-weight-bold.text-md-h2.pt-6.pt-md-15 {{ $t("введите_номер_телефона") }}
            p.mt-8.mb-2.text-body-1.text-md-subtitle-1 {{ $t("номер_телефона") }}
            v-text-field.rounded-xl(
                    ref="phoneNumber"
                    v-model="phoneNumber"
                    v-mask="'+7 (###) ###-##-##'"
                    type="tel"
                    placeholder="+7 (###) ###-##-##"
                    :disabled="signInWithPhoneNumberLoading"
                    autocomplete="off"
                    @input="phoneNumberError = null"
                    :error-messages="phoneNumberError"
                    :hint="$t('отправим_код_подтверждения')"
                    persistent-hint
                    filled)
            v-btn.font-weight-bold.text-h5.text-md-h4.mt-12(:large="isLarge" :disabled="!phoneNumber || phoneNumber.length !== 18 || signInWithGoogleLoading || signInWithFaceTouchIdLoading" block color="primary" type="submit" :loading="signInWithPhoneNumberLoading" @click.prevent="sendCode()") {{ $t("продолжить") }}
          p.text-center.text-body-1.mt-6.text-md-subtitle-1.additional--text {{ $t("нажимая_кнопку_продолжить_вы_принимаете_условия") }} &nbsp;
            a(@click="$openLink(`https://link.rekassa.kz/app-agreement${locale === 'ru' ? '' : '-kk'}`, '_system')") {{ $t("пользовательского_соглашения") }}

          v-btn.black--text.font-weight-bold.text-h5.text-md-h4.mt-12(v-if="googleEnabled" :large="isLarge" color="white" :loading="signInWithGoogleLoading" @click="signInWithGoogle()")
            v-icon(small left color="red") mdi-google
            | {{ $t("войти_через_type", { type: 'Google' }) }}

          v-btn.white--text.font-weight-bold.text-h5.text-md-h4.mt-12(v-if="faceTouchIdEnabled" :large="isLarge" color="black" :loading="signInWithFaceTouchIdLoading" @click="signInWithFaceTouchId()")
            v-icon(small left) {{ faceTouchIdType === 'Face ID' ? 'mdi-face-recognition' : 'mdi-fingerprint' }}
            | {{ $t("войти_через_type", { type: faceTouchIdType }) }}

      // --------------------------------------------------------------------------------------------
      template(v-if="step === 'ENTER_CONFIRMATION_CODE'")
        .d-flex.flex-column.align-self-start.re-fill-width
          p.text-h3.font-weight-bold.text-md-h2.text-center.pt-6.pt-md-15 {{ $t("мы_отправили_код_в_sms") }}
          p.text-center.text-subtitle-2.text-md-h5.mt-6.mt-md-12.mb-6 {{ $t("значный_код_отправлен_по_номеру") }}
            br
            | {{ phoneNumber }}
          re-otp-input.px-3.my-0(v-model="confirmationCode" :error.sync="confirmationCodeError" :disabled="signInLoading" @action="verifyCode")
          v-col.py-1(cols="12" align="center")
            v-btn.text-md-h5.text-center.mt-3(text color="secondary" :disabled="confirmationCodeCounter > 0"  @click.prevent="signInWithAlternativesBottomSheet = true; analyticsLogEvent({ eventName: 're_auth_user_requested_alternative_confimation' })") {{ $t("не_пришел_код") }} ({{ confirmationCodeTimeLeft }})
          v-btn.font-weight-bold.text-h5.text-md-h4.mt-5(:disabled="!(confirmationCode && confirmationCode.length === 6)" color="primary" block :large="isLarge" type="submit" :loading="signInLoading" @click.prevent="verifyCode()") {{ $t("продолжить") }}

      v-img.right-image.d-none.d-md-block(src="/static/icons/right-shape.svg")

  v-bottom-sheet(v-model="signInWithAlternativesBottomSheet" inset width="400")
    v-sheet.rounded-t-lg
      v-card.rounded-t-lg
        v-card-title.pb-0
          v-row
            v-col.pr-0.align-self-center(cols="10")
              span.headline {{ $t("попробуйте_войти_другим_способом") }}
            v-col.pl-0(cols="2" align="right")
              v-btn(icon @click="signInWithAlternativesBottomSheet = false")
                v-icon mdi-close
        v-card-text.px-0
          v-container.px-2
            v-col(cols="12")
              v-btn(block color="blue" @click="signInWithAlternativesBottomSheet = false; signInWithTelegram()")
                v-icon(small left) mdi-send
                | {{ $t("войти_через_telegram") }}

  re-connection-warning
</template>
<script>
import { mapState, mapActions } from 'vuex'
import i18n, { getLocale, changeLocale } from '../../i18n'
import ConnectionWarning from '../utils/ConnectionWarning.vue'
import OTPInput from '../utils/OTPInput.vue'

export default {
  components: {
    're-connection-warning': ConnectionWarning,
    're-otp-input': OTPInput,
  },

  data: () => ({
    step: (localStorage.getItem('rekassa.kz-auth-onboarding-seen') || 'false') === 'true' ? 'ENTER_PHONE_NUMBER' : 'ONBOARDING',

    phoneNumber: null,
    phoneNumberError: null,

    otpPhone: null,
    otpHash: null,

    confirmationCode: null,
    confirmationCodeTimeLeft: null,
    confirmationCodeCounter: null,
    confirmationCodeTimer: null,
    confirmationCodeError: null,

    signInWithTokenLoading: false,

    signInWithPhoneNumberLoading: false,
    signInLoading: false,

    googleEnabled: false,
    signInWithGoogleLoading: false,

    faceTouchIdEnabled: false,
    faceTouchIdType: null,
    signInWithFaceTouchIdLoading: false,

    signInWithAlternativesBottomSheet: false,

    rules: {
      required: v => !!v || this.$t('требуется_заполнить'),
    },

    onboardingStep: 0,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      helpCrunchUnreadCount: state => state.tools.helpCrunchUnreadCount,
    }),

    locale() {
      return getLocale()
    },

    isLarge() {
      return this.$vuetify.breakpoint.mdAndUp
    },

    onboardingSlides() {
      return [
        {
          title: this.$t('ведите_весь_бизнес_в_одном_приложении'),
          text: this.$t('да_представьте_rekassa_это_возможно'),
          image: 'onboarding1',
        },
        {
          title: this.$t('управляйте_ассортиментом'),
          text: this.$t('создавайте_списки_и_категории_товаров_чтобы_выбивать_чеки_быстрее_и_удобнее'),
          image: 'onboarding2',
        },
        {
          title: this.$t('принимайте_платежи'),
          text: this.$t('выбирайте_удобный_способ_наличными_картой_переводом_rekassa_принимает_все'),
          image: 'onboarding3',
        },
        {
          title: this.$t('пробивайте_чеки'),
          text: this.$t('фискализируйте_продажи_вручную_или_автоматически_а_rekassa_доставит_их_в_налоговую'),
          image: 'onboarding4',
        },
        {
          title: this.$t('хорошая_идея_это_уже_половина_дела'),
          text: this.$t('пройдите_регистрацию_чтобы_начать_прямо_сейчас'),
          image: 'onboarding5',
        },
      ]
    },
  },

  watch: {
    '$route.query.token': {
      handler(token) {
        if (token) {
          this.signInWithTokenLoading = true
          this.signInWithCustomToken({ token }).then((tokenResult) => {
            this.setUser(tokenResult.user).then(() => {
              this.init().then(() => {
                this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Token' } })
                this.$router.push('/')
                localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
                localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
                localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
              })
            })
          }).catch(() => {
            this.signInWithTokenLoading = false
            this.showSnackbar({ message: i18n.t('не_удалось_войти_по_токену') })
            this.signInLoading = false
          })
        }
      },
      deep: true,
      immediate: true,
    },
  },

  created() {
    if (this.user) {
      this.$router.push('/')
    }

    // iOS Face Touch ID
    if (this.$isCordova() && !this.$isAndroid()) {
      this.faceTouchIdEnabled = (localStorage.getItem('rekassa.kz-auth-face-touch-id-enabled') || 'false') === 'true'
      this.faceTouchIdType = localStorage.getItem('rekassa.kz-auth-face-touch-id-type')
    }

    // Google
    if (this.$isCordova() && this.$isAndroid()) {
      this.googleEnabled = (localStorage.getItem('rekassa.kz-auth-google-enabled') || 'false') === 'true'
    }

    if (this.step === 'ENTER_PHONE_NUMBER') {
      this.preparePhoneNumber()
    }
  },

  beforeRouteEnter(to, from, next) {
    // Костыль для Мой учет.kz(rekassa as iframe)
    if (to.query.theme) {
      localStorage.setItem('rekassa.kz-ui-darkTheme', to.query.theme === 'light' ? 'false' : 'true')
    }

    next()
  },

  beforeDestroy() {
    // Восстановить тему
    const darkTheme = localStorage.getItem('rekassa.kz-ui-darkTheme') === null || localStorage.getItem('rekassa.kz-ui-darkTheme') === 'true'
    this.$vuetify.theme.dark = darkTheme
  },

  methods: {
    ...mapActions({
      sendOtp: 'auth/sendOtp',
      verifyOtp: 'auth/verifyOtp',
      signInWithCustomToken: 'auth/signInWithCustomToken',
      setUser: 'auth/setUser',
      init: 'cashRegisters/init',
      setConnectionWarning: 'tools/setConnectionWarning',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
      firebaseRemoteConfigGetString: 'tools/firebaseRemoteConfigGetString',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    preparePhoneNumber() {
      this.phoneNumber = localStorage.getItem('rekassa.kz-auth-last-phone-number')
      if (this.confirmationCodeTimer) {
        clearInterval(this.confirmationCodeTimer)
      }
      this.$nextTick(() => {
        this.$refs.phoneNumber.focus()
      })
    },

    onboardingNext() {
      if (this.onboardingStep + 1 === this.onboardingSlides.length) {
        localStorage.setItem('rekassa.kz-auth-onboarding-seen', 'true')
        this.step = 'ENTER_PHONE_NUMBER'
        this.preparePhoneNumber()
      } else {
        this.onboardingStep += 1
      }
    },

    onboardingPrev() {
      if (this.onboardingStep - 1 >= 0) {
        this.onboardingStep -= 1
      }
    },

    back() {
      this.confirmationCodeError = null
      this.phoneNumberError = null
      this.step = this.step === 'ENTER_PHONE_NUMBER' ? 'ONBOARDING' : 'ENTER_PHONE_NUMBER'

      if (this.step === 'ENTER_PHONE_NUMBER') {
        this.preparePhoneNumber()
      }
    },

    callCenter() {
      document.location.href = 'tel:+77777779449'
      this.analyticsLogEvent({ eventName: 're_auth_call_button_click' })
    },

    signInWithGoogle() {
      if (this.signInWithGoogleLoading) return
      this.signInWithGoogleLoading = true
      window.FirebasePlugin.authenticateUserWithGoogle(process.env.VUE_APP_GOOGLE_PROVIDE_ID, (credential) => {
        this.cordovaSignInWithCredential(credential)
      }, (error) => {
        console.debug(error)
        this.signInWithGoogleLoading = false
        this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: 'Google' }) })
      })
    },

    signInWithFaceTouchId() {
      this.signInWithFaceTouchIdLoading = true
      setTimeout(() => {
        window.Fingerprint.loadBiometricSecret({
          description: this.$t('вход_в_систему'),
          disableBackup: true,
        }, (result) => {
          this.cordovaSignInUserWithEmailAndPassword(result.split(':')[0], result.split(':')[1])
        }, (error) => {
          console.debug(error)
          this.signInWithFaceTouchIdLoading = false
          this.showSnackbar({ message: this.$t('не_удалось_войти_через_type', { type: this.faceTouchIdType }) })
        })
      }, 500)
    },

    signInWithTelegram() {
      this.$openLink(`https://t.me/${process.env.VUE_APP_TELEGRAM_BOT_NAME}`, '_system')
      this.analyticsLogEvent({ eventName: 're_auth_auth_telegram_button_click' })
    },

    sendCode() {
      this.analyticsLogEvent({ eventName: 're_auth_user_filled_phone_number' })

      this.signInWithPhoneNumberLoading = true
      this.otpPhone = `+${this.phoneNumber.replace(/[^\d]/g, '')}`

      this.sendOtp({ data: { phone: this.otpPhone } }).then(result => {
        this.otpHash = result.hash
        this.step = 'ENTER_CONFIRMATION_CODE'
        this.signInWithPhoneNumberLoading = false

        // Countdown
        this.confirmationCodeTimeLeft = '0:25'
        this.confirmationCodeCounter = 25
        this.confirmationCodeTimer = setInterval(() => {
          if (this.confirmationCodeCounter <= 0) {
            clearInterval(this.confirmationCodeTimer)
          } else {
            this.confirmationCodeCounter -= 1
            this.confirmationCodeTimeLeft = `0:${this.$options.filters.numeral(this.confirmationCodeCounter, '00')}`
          }
        }, 1000)
      }).catch((error) => {
        if (error.response.data && error.response.data.code) {
          this.phoneNumberError = this.$t(`sms/${error.response.data.code}`)
        } else {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
        this.signInWithPhoneNumberLoading = false
      })
    },

    verifyCode() {
      this.analyticsLogEvent({ eventName: 're_auth_user_entered_confirmation_code' })
      this.signInLoading = true
      this.verifyOtp({ data: { phone: this.otpPhone, hash: this.otpHash, otp: this.confirmationCode } }).then(result => {
        this.signInWithCustomToken({ token: result.token }).then((tokenResult) => {
          this.setUser(tokenResult.user).then(() => {
            this.init().then(() => {
              this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Kazinfotech' } })
              if (this.$route.query.from && !this.$route.query.from.includes('signin')) {
                this.$router.push(this.$route.query.from, () => {})
              } else {
                this.$router.push('/', () => {})
              }
              localStorage.setItem('rekassa.kz-auth-last-phone-number', this.phoneNumber)
              localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
              localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
              localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
            })
          })
        }).catch((error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
          this.signInLoading = false
        })
      }).catch((error) => {
        if (error.response.data && error.response.data.code) {
          this.confirmationCodeError = this.$t(`sms/${error.response.data.code}`)
        } else {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
        this.signInLoading = false
      })
    },

    cordovaSignInWithCredential(credential) {
      window.FirebasePlugin.signInWithCredential(credential, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          // Signing with Google
          if (this.signInWithGoogleLoading) {
            // Allow only users that have phoneNumbers
            if (user.phoneNumber) {
              this.setUser(user).then(() => {
                this.init().then(() => {
                  this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Google' } })
                  this.$router.push('/', () => {})
                })
              })
            } else {
            // Else logout and show warning
              window.FirebasePlugin.signOutUser()
              this.signInWithGoogleLoading = false
              this.step = 'ENTER_PHONE_NUMBER'
              this.showSnackbar({ message: this.$t('данная_учетная_запись_google_не_привязана_к_rekassa_выполните_вход_через_номер_телефона') })
            }
          // SMS auth
          } else {
            this.setUser(user).then(() => {
              this.init().then(() => {
                this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'Sms' } })
                this.$router.push('/', () => {})
                localStorage.setItem('rekassa.kz-auth-fresh-signin', 'true')
                localStorage.setItem('rekassa.kz-auth-face-touch-id-enabled', 'false')
                localStorage.setItem('rekassa.kz-auth-google-enabled', 'false')
              })
            })
          }
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithPhoneNumberLoading = false
        this.signInLoading = false
        if (error) {
          if (error.includes('ERROR_INVALID_VERIFICATION_CODE')) {
            this.confirmationCodeError = this.$t('введен_некорректный_код_подтверждения')
          } else if (error.includes('ERROR_SESSION_EXPIRED')) {
            this.confirmationCodeError = this.$t('срок_кода_подтверждения_уже_истек')
          } else this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        }
      })
    },

    cordovaSignInUserWithEmailAndPassword(email, password) {
      window.FirebasePlugin.signInUserWithEmailAndPassword(email, password, () => {
        window.FirebasePlugin.getCurrentUser((user) => {
          this.setUser(user).then(() => {
            this.init().then(() => {
              this.analyticsLogEvent({ eventName: 'login', eventProperties: { type: 'FaceId/TouchId' } })
              this.$router.push('/', () => {})
            })
          })
        }, (error) => {
          this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
        })
      }, (error) => {
        this.signInWithFaceTouchIdLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    switchLocale(locale) {
      changeLocale(locale)
    },
  },
}
</script>
<style lang="stylus" scoped>
.plain-btn
  background-color transparent !important
  opacity 1 !important
  box-shadow none !important
  text-transform none !important
  color inherit !important
  padding 0 !important
  &:hover
    background transparent !important
    opacity 1 !important

  @media (max-width 960px) {
    min-width 40px !important
    height 40px !important
  }

.max-width
  max-width 440px !important

.left-image
  position fixed
  width 300px
  height 470px
  left -5px
  top 50%
  margin-top -235px

.right-image
  position fixed
  width 300px
  height 470px
  right -5px
  top 50%
  margin-top -235px

.bottom-sheet
  border-radius 20px 20px 0px 0px
</style>

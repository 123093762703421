<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/services')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("KASPI_QR") }}
      v-spacer
      template(v-if="user._links.createCashRegister && cashRegister.status === 'REGISTERED' && !kaspiQRConnected")
        v-btn(:disabled="loading" outlined @click="preConnect()") {{ $t("подключить") }}
    v-progress-linear(:active="loading" :indeterminate="loading" fixed top color="secondary")

  v-main(flat)
    v-container.pt-0(style="max-width: 450px")
      v-row
        v-col.pt-5(align="center")
          v-img.rounded-xl(:src="`/static/kaspi-qr-banner-${locale}.png`" width="100%" eager)

      v-row
        v-col
          .subtitle-1.my-2 {{ $t("принимайте_платежи_с_помощью_QR_кода_чек_пробьется_автоматически") }}

      v-row
        v-col.pt-0
          v-list.re-v-list-fulltext.rounded-t-lg.rounded-b-lg
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-receipt-text-check-outline
              v-list-item-content
                v-list-item-title {{ $t("легкий_способ_оплаты_напрямую_в_rekassa") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-apps
              v-list-item-content
                v-list-item-title {{ $t("быстрое_создание_добавление_QR_кода") }}
            v-divider
            v-list-item
              v-list-item-icon.ml-2.mr-6
                v-icon mdi-printer-pos-check
              v-list-item-content
                v-list-item-title {{ $t("чек_создается_автоматически") }}

      v-row(v-if="cashRegister.status === 'REGISTERED'")
        v-col
          v-list
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("статус") }}
              v-list-item-action
                v-list-item-action-text.subtitle-1 {{ kaspiQRConnected ? $t("подключено") : $t("не_подключено") }}

            div.px-4(v-if="user._links.createCashRegister")
              v-btn.my-3(v-if="!kaspiQRConnected" color="secondary" block :disabled="loading" @click="preConnect()") {{ $t("подключить") }}
              v-btn.my-3(v-else color="error" block :disabled="loading" @click="showDisconnectPinpad()") {{ $t("отключить") }}

  v-dialog(v-model="kaspiQRDialog" max-width="450px" :fullscreen="$vuetify.breakpoint.xsOnly")
    v-card.px-2
      v-card-title.pb-0.pt-10
        .text-h3 {{ $t("подключение_каспи_qr") }}
        v-spacer
        v-btn.ml-1(icon @click="kaspiQRDialog = false" :disabled="loading")
          v-icon mdi-close
      v-card-text
        v-container.px-0
          v-row
            v-col
              .text-subtitle-1 {{ $t("для_подключения_необходимо_загрузить_статический_qr_код_получить_qr_код_можно_в_приложении_каспи_пей") }} &nbsp;
                span.subtitle-1.secondary--text(@click="$openLink(locale === 'ru' ? 'https://guide.kaspi.kz/partner/ru/pos/payments/printed_qr/q1854' : 'https://guide.kaspi.kz/partner/kz/pos/payments/printed_qr/q1854', '_system')") {{ $t("подробнее") }}...

          v-row.d-none
            v-col
              div(id="qrcode-capture")
                re-qrcode-capture(@detect="onDetect" :multiple="false" :capture="null")

          v-row
            v-col
              v-btn(outlined :loading="qrDetectLoading" :disabled="qr !== null" @click="uploadImage()")
                v-icon(left) mdi-image
                |  {{ $t("загрузить_qr_код") }}
              v-icon.ml-4(v-if="qr !== null" left color="secondary") mdi-check-all

          v-row
            v-col
              v-alert(type="error" color="warning" text) {{ $t("использовать_каспи_qr_можно_только_если_у_вас_отсутствует_каспи_касса_чтобы_предотвратить_дублирование_фискальных_операций") }}

          v-row
            v-col
              v-btn(color="secondary" block @click="showConnectPinpad()") {{ $t("подключить") }}

  re-pinpad(v-model="connectDialog" :title="$t('подключение_name', { name: 'Каспи QR'})" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_name', { name: 'Каспи QR'})" :loading="loading" :errorText.sync="connectErrorMessage" @action="connect")
  re-pinpad(v-model="disconnectDialog" :title="$t('отключение_name', { name: 'Каспи QR'})" :subtitle="$t('введите_пин-код')" :text="$t('для_отключения_name', { name: 'Каспи QR'})" :loading="loading" :errorText.sync="disconnectErrorMessage" @action="disconnect")

</template>
<script>
import { QrcodeCapture } from 'vue-qrcode-reader'
import { mapState, mapActions } from 'vuex'
import { getLocale } from '../../i18n'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    're-pinpad': Pinpad,
    're-qrcode-capture': QrcodeCapture,
  },

  data: () => ({
    loading: false,
    qrDetectLoading: false,

    kaspiQRDialog: false,
    qr: null,

    connectDialog: false,
    connectErrorMessage: null,
    disconnectDialog: false,
    disconnectErrorMessage: null,
  }),

  computed: {
    ...mapState({
      user: state => state.auth.user,
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
      organization: state => state.cashRegisters.cashRegister.organization,
      configuration: state => state.cashRegisters.cashRegister.cashRegister.data.configuration,
    }),

    locale() {
      return getLocale()
    },

    kaspiQRConnected() {
      return this.configuration.kaspiQR && this.configuration.kaspiQR.connected
    },
  },

  methods: {
    ...mapActions({
      saveConfiguration: 'cashRegisters/saveConfiguration',
      selectCashRegister: 'cashRegisters/selectCashRegister',
      showSnackbar: 'tools/showSnackbar',
      showConfirm: 'tools/showConfirm',
      analyticsLogEvent: 'analytics/logEvent',
    }),

    isShiftClosed() {
      if (this.cashRegister.shiftOpen) {
        this.showConfirm({
          title: this.$t('требуется_закрыть_смену_чтобы_поменять_данный_параметр'),
          resolveText: this.$t('закрыть_смену'),
          rejectText: this.$t('отменить'),
        }).then(() => {
          this.$router.push(`/shifts/${this.cashRegister.id}/${this.cashRegister.shiftNumber}/zxreport`)
        }).catch(() => {})
        return false
      }
      return true
    },

    preConnect() {
      if (this.isShiftClosed()) {
        this.qr = null
        this.kaspiQRDialog = true
      }
    },

    uploadImage() {
      document.getElementById('qrcode-capture').getElementsByTagName('input')[0].click()
    },

    async onDetect(promise) {
      this.qrDetectLoading = true
      try {
        const { content } = await promise

        if (content === null) {
          this.showSnackbar({ message: this.$t('qr_код_не_найден') })
        } else {
          this.showSnackbar({ message: this.$t('qr_код_загружен') })
          this.qr = content
        }

        this.qrDetectLoading = false
      } catch (error) {
        this.showSnackbar({ message: this.$t('произошла_ошибка') })
        this.qrDetectLoading = false
      }
    },

    showConnectPinpad() {
      if (this.qr === null) {
        this.showSnackbar({ message: this.$t('загрузите_qr_код') })
        return
      }

      this.kaspiQRDialog = false
      this.connectDialog = true
    },

    showDisconnectPinpad() {
      if (this.isShiftClosed()) {
        this.disconnectDialog = true
      }
    },

    getModifiedConfiguration(connected, qr) {
      return {
        ...this.configuration,
        kaspiQR: {
          connected,
          qr,
        },
      }
    },

    connect(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(true, this.qr),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_подключен_name', { name: 'Каспи QR' }) })
          this.analyticsLogEvent({ eventName: 're_kaspi_qr_connected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.connectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.connectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },

    disconnect(pincode) {
      this.loading = true
      this.saveConfiguration({
        cashRegister: this.cashRegister,
        configuration: this.getModifiedConfiguration(false),
        password: pincode,
      }).then(() => {
        this.selectCashRegister(this.cashRegister.id).then(() => {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('успешно_отключен_name', { name: 'Каспи QR' }) })
          this.analyticsLogEvent({ eventName: 're_kaspi_qr_disconnected', eventProperties: { date: this.$moment().format('DD-MM-YYYY') } })
        })
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.disconnectErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.disconnectDialog = false
          this.loading = false
          this.showSnackbar({ message: this.$t('произошла_ошибка') })
        }
      })
    },
  },
}
</script>

<style lang="stylus" scoped>
.theme--dark.v-card > .v-card__text,
.theme--light.v-card > .v-card__text
  color inherit !important
</style>

<template lang="pug">
v-app
  v-app-bar.re-app-bar(app :height="$vuetify.breakpoint.mdAndUp ? 70 : 60")
    v-container.d-flex.align-center
      v-btn(icon @click="$router.push('/settings')")
        v-icon mdi-arrow-left
      v-toolbar-title {{ $t("сторонние_приложения") }}

  v-main(flat)
    v-container(style="max-width: 450px")
      v-row
        v-col
          v-list.re-v-list-fulltext
            v-list-item
              v-list-item-content
                v-list-item-title {{ $t("в_данном_разделе_вы_можете_получить_пароль_для_подключения_стороннего_приложения") }}.
                  a(@click.prevent="$openLink('https://link.rekassa.kz/access-api-password', '_system')") &nbsp; {{ $t("подробнее") }}

      v-row
        v-col
          v-btn.my-2(block color="primary" @click="createNewApiAccess()") {{ $t("подключить_приложение") }}

      v-row
        v-col.pt-0
          v-subheader {{ $t("подключенные_приложения") }}
          v-progress-linear(v-if="apiAccessListLoading" active indeterminate color="secondary" value="100")
          div(v-else style="height: 4px;")
          v-list(v-if="apiAccessList.length > 0")
            v-list-item(v-for="(item, $index) in apiAccessList" :key="'a_' + $index")
              v-list-item-content
                v-list-item-title(:class="{ 'text-decoration-line-through' : item.status === 'DISABLED' }") {{ item.partner.meta.name }}
                v-list-item-subtitle {{ item.issued | moment("DD.MM.YYYY HH:mm") }}
              v-list-item-action
                v-btn(icon :disabled="apiAccessListLoading" @click="$openLink(item.partner.meta.url, '_system')")
                  v-icon() mdi-information
              v-list-item-action.ml-2
                v-btn(icon :disabled="apiAccessListLoading" @click="preRevoke(item)")
                  v-icon(color="error") mdi-trash-can
          div(v-if="apiAccessListLoading && apiAccessList.length === 0")
            v-skeleton-loader.mt-2(type="list-item-two-line" boilerplate)

  v-dialog(v-model="newApiAccessDialog" max-width="400px")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("выберите_приложение") }}
      v-card-text
        v-container
          v-form.mt-3(ref="newApiAccessForm" @submit.prevent="preGrant()")
            v-select(:label="$t('приложение')" v-model="partnerTypeSelect" :items="partnerTypes" return-object item-text="meta.name" :rules="[rules.required]")
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined @click="newApiAccessDialog = false") {{ $t("отменить") }}
        v-btn(color="primary" @click="preGrant()") {{ $t("получить_пароль") }}

  v-dialog(v-model="newApiAccessCreatedDialog" persistent max-width="400px" :fullscreen="$vuetify.breakpoint.xsOnly")
    v-card
      v-card-title.pb-0
        span.headline {{ $t("пароль_сформирован") }}
      v-card-text.py-0
        v-container.px-0
          v-row(row wrap)
            v-col.pb-6
              .subtitle(v-dompurify-html="$t('используйте_данный_пароль_вместе_с_dotdotdot', { name: partnerTypeSelect ? partnerTypeSelect.meta.name : '' })")
          v-row(row wrap)
            v-col.pb-0
              v-text-field.append-button.monospaced(outlined readonly :value="cashRegister.serialNumber" :label="`${$t('заводской_номер')} (${$t('знм')})`")
                template(v-slot:append)
                  v-btn(icon @click="copySerialNumber(cashRegister.serialNumber)")
                    v-icon mdi-content-copy
          v-row(row wrap)
            v-col.py-0
              v-textarea.monospaced(outlined readonly no-resize rows="2" :value="password" :label="$t('пароль')")
                template(v-slot:append)
                  v-btn(icon @click="copyPassword(password)")
                    v-icon mdi-content-copy

          v-row(row wrap)
            v-col.pt-0.text-center
              span.subtitle {{ $t("или_отсканируйте_qr_код_в_приложении", { name: partnerTypeSelect ? partnerTypeSelect.meta.name : '' }) }}

          v-row.mb-7(row wrap justify="center")
            div.re-qrcode
              qrcode(:value="qrcode" size="200" level="L" renderAs="svg")

          v-row(row wrap)
            v-col.pt-0
              div.mb-2
                v-icon.mr-2(small color="warning") mdi-alert
                | {{ $t('сохраните_пароль_сейчас') }}
              div {{ $t('пароль_показывается_только_один_раз_dotdotdot') }}

      v-card-actions
        v-btn.mb-3(block color="primary" @click="newApiAccessCreatedDialog = false") {{ $t("я_сохранил_закрыть") }}

  re-pinpad(v-model="grantApiAccessPinDialog" :title="$t('подключение_приложения')" :subtitle="$t('введите_пин-код')" :text="$t('для_подключения_приложения')" :loading="loading" :errorText.sync="grantApiAccessPinErrorMessage" @action="grant")
  re-pinpad(v-model="revokeApiAccessPinDialog" :title="$t('удаление_приложения')" :subtitle="$t('введите_пин-код')" :text="$t('для_удаления_приложения')" :loading="loading" :errorText.sync="revokeApiAccessPinErrorMessage" @action="revoke")

</template>
<script>
import { mapState, mapActions } from 'vuex'
import qrcode from 'qrcode.vue'
import store from '../../store/index'
import Pinpad from '../utils/PinpadDialog.vue'

export default {
  components: {
    qrcode,
    're-pinpad': Pinpad,
  },

  data: () => ({
    newApiAccessDialog: false,
    apiAccessListLoading: false,
    apiAccessList: [],
    partnerTypeSelect: null,
    partnerTypes: null,

    grantApiAccessPinDialog: false,
    grantApiAccessPinErrorMessage: null,
    revokeApiAccessPartner: null,
    revokeApiAccessPinDialog: false,
    revokeApiAccessPinErrorMessage: null,
    loading: false,

    newApiAccessCreatedDialog: false,
    password: null,
    qrcode: null,
  }),

  computed: {
    ...mapState({
      cashRegister: state => state.cashRegisters.cashRegister.cashRegister,
    }),
  },

  created() {
    this.init()
  },

  beforeRouteEnter(to, from, next) {
    if (store.state.cashRegisters.cashRegister.cashRegister.status === 'REGISTERED') {
      next()
    } else {
      next('/')
    }
  },

  methods: {
    ...mapActions({
      fetchApiPartners: 'cashRegisters/fetchApiPartners',
      fetchApiAccess: 'cashRegisters/fetchApiAccess',
      grantApiAccess: 'cashRegisters/grantApiAccess',
      revokeApiAccess: 'cashRegisters/revokeApiAccess',
      copyToClipboard: 'tools/copyToClipboard',
      showConfirm: 'tools/showConfirm',
      showSnackbar: 'tools/showSnackbar',
    }),

    init() {
      this.apiAccessListLoading = true
      this.fetchApiAccess({
        cashRegister: this.cashRegister,
      }).then((result) => {
        this.apiAccessListLoading = false
        this.apiAccessList = result.data
          .filter((item) => (item.partner.meta.hidden === false))
          .sort((a, b) => this.$moment(a.issued).isAfter(this.$moment(b.issued)))
      }).catch((error) => {
        this.apiAccessListLoading = false
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })

      this.fetchApiPartners().then((result) => {
        this.partnerTypes = result.data.filter((item) => item.status === 'ENABLED' && item.meta.hidden === false).sort((a, b) => a.meta.name.localeCompare(b.meta.name))
      }, (error) => {
        this.showSnackbar({ message: `${this.$t('произошла_ошибка')}: ${error}` })
      })
    },

    createNewApiAccess() {
      this.partnerTypeSelect = null
      this.newApiAccessDialog = true
      this.$nextTick(() => {
        if (!this.$isCordova()) {
          this.$refs.newApiAccessForm.resetValidation()
        }
      })
    },

    preGrant() {
      if (this.$refs.newApiAccessForm.validate()) {
        this.newApiAccessDialog = false
        this.grantApiAccessPinDialog = true
      }
    },

    grant(pincode) {
      this.loading = true
      this.grantApiAccess({
        partner: this.partnerTypeSelect.name,
        cashRegister: this.cashRegister,
        password: pincode,
      }).then((result) => {
        this.loading = false
        this.grantApiAccessPinDialog = false
        this.password = result.data.password
        this.qrcode = `{"n":"${this.cashRegister.serialNumber}","p":"${result.data.password}"}`
        this.newApiAccessCreatedDialog = true
        this.init()
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.grantApiAccessPinErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.grantApiAccessPinDialog = false
        }
      })
    },

    copySerialNumber(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('заводской_номер_скопирован', { number: str }) })
    },

    copyPassword(str) {
      this.copyToClipboard(str)
      this.showSnackbar({ message: this.$t('пароль_скопирован', { password: str }) })
    },

    preRevoke(item) {
      this.showConfirm({
        title: this.$t('удалить_приложение', { name: item.partner.meta.name }),
        resolveText: this.$t('удалить'),
        rejectText: this.$t('отменить'),
        persistent: true,
      }).then(() => {
        this.revokeApiAccessPartner = item
        this.revokeApiAccessPinDialog = true
      }).catch(() => {})
    },

    revoke(pincode) {
      this.loading = true
      this.revokeApiAccess({
        partner: this.revokeApiAccessPartner.partner.name,
        cashRegister: this.cashRegister,
        password: pincode,
      }).then(() => {
        this.loading = false
        this.revokeApiAccessPinDialog = false
        this.showSnackbar({ message: this.$t('приложение_успешно_удалено', { name: this.revokeApiAccessPartner.partner.meta.name }) })
        this.init()
      }).catch((error) => {
        if (error && error.response && error.response.data && error.response.data.code === 'WRONG_PASSWORD') {
          this.loading = false
          this.revokeApiAccessPinErrorMessage = this.$t('backend/WRONG_PASSWORD')
        } else {
          this.showSnackbar({ message: this.$t('произошла_ошибка', { error }) })
          this.loading = false
          this.revokeApiAccessPinDialog = false
        }
      })
    },
  },
}
</script>

<style lang="stylus">
.append-button .v-input__append-inner
  margin-top 10px !important
.monospaced input
  font-family monospace !important
.monospaced textarea
  font-family monospace !important
  line-height 20px
  padding-top 10px
  padding-bottom 15px
</style>

/* eslint-disable consistent-return */
import './styluses/main.styl'
import './styluses/fonts.css'

import '@formatjs/intl-datetimeformat/polyfill-force'
import '@formatjs/intl-datetimeformat/locale-data/ru'
import '@formatjs/intl-datetimeformat/locale-data/kk'
import '@formatjs/intl-datetimeformat/add-all-tz'

import Vue from 'vue'
import { initializeApp } from 'firebase/app'
import { getAnalytics } from 'firebase/analytics'
import { getRemoteConfig, fetchAndActivate } from 'firebase/remote-config'
import { getAuth } from 'firebase/auth'
import numeral from 'numeral'
import 'numeral/locales'
import vueMoment from 'vue-moment'
import moment from 'moment-timezone'
import 'moment/locale/ru'
import 'moment/locale/kk'
import { VueMaskDirective } from 'v-mask'
import JsonViewer from 'vue-json-viewer'
import VueDOMPurifyHTML from 'vue-dompurify-html'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import store from './store'
import i18n from './i18n'
import rules from './utils/rules'
import { initDeepLinkHandling, isDeepLink, getDeepLinkRoute } from './utils/deeplink'

Vue.config.productionTip = false

function initWebFirebase() {
  const firebaseConfig = JSON.parse(process.env.VUE_APP_FIREBASE_CONFIG)
  const firebaseApp = initializeApp(firebaseConfig)
  store.dispatch('tools/setFirebaseApp', firebaseApp)
}

function initWebFirebaseAuth() {
  const auth = getAuth(store.state.tools.firebaseApp)
  auth.useDeviceLanguage()
  store.dispatch('auth/setFirebaseAuth', auth)
}

function initWebHelpCrunch() {
  /* eslint-disable */
  window.helpcrunchSettings = {
    organization: 'apprekassa',
    appId: '57bcceb5-963f-4970-a1ef-b20ce90d53f3',
  };
  (function(w,d){var hS=w.helpcrunchSettings;if(!hS||!hS.organization)
  {return;}var widgetSrc='https://'+hS.organization+'.widget.helpcrunch.com/';
  w.HelpCrunch=function(){w.HelpCrunch.q.push(arguments)};w.HelpCrunch.q=[];
  function r(){if (d.querySelector('script[src="' + widgetSrc + '"')) { return; }
  var s=d.createElement('script');s.async=1;s.type='text/javascript';s.src=widgetSrc;(d.body||d.head).appendChild(s);
  }if(d.readyState === 'complete'||hS.loadImmediately){r();} 
  else if(w.attachEvent){w.attachEvent('onload',r)}else
  {w.addEventListener('load',r,false)}})(window, document)
  /* eslint-enable */

  window.HelpCrunch('onNewUnreadChat', (data) => {
    store.dispatch('tools/setHelpCrunchUnreadCount', data.unreadChats)
  })

  window.HelpCrunch('onChatClose', () => {
    window.HelpCrunch('hideChatWidget')
  })
}

function initFacebookPixel() {
  /* eslint-disable */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  fbq('init', '852069506206285');
  /* eslint-enable */
}

function initCordovaHelpCrunch() {
  window.cordova.plugins.HelpCrunchPlugin.initialize({
    organization: process.env.VUE_APP_HELPCRUNCH_ORGANIZATION,
    applicationId: process.env.VUE_APP_HELPCRUNCH_APP_ID,
    applicationSecret: process.env.VUE_APP_HELPCRUNCH_APP_SECRET,
  })

  window.addEventListener('onNewUnreadChat', (event) => {
    store.dispatch('tools/setHelpCrunchUnreadCount', event.count)
  }, false)
}

function initFirebaseAnalytics() {
  if (window.cordova) {
    window.FirebasePlugin.setAnalyticsCollectionEnabled(true)
  } else {
    const analytics = getAnalytics(store.state.tools.firebaseApp)
    store.dispatch('analytics/setFirebaseAnalytics', analytics)
  }
}

function initAppsFlyer() {
  if (window.cordova !== undefined && window.device.platform === 'iOS') {
    setTimeout(() => {
      window.cordova.plugins.idfa.requestPermission().then(() => {
        window.plugins.appsFlyer.initSdk({
          devKey: process.env.VUE_APP_APPS_FLYER_DEV_KEY,
          isDebug: false,
          appId: process.env.VUE_APP_APPS_FLYER_APP_ID,
          waitForATTUserAuthorization: 10,
        })
      })
    }, 5000)
  } else {
    window.plugins.appsFlyer.initSdk({
      devKey: process.env.VUE_APP_APPS_FLYER_DEV_KEY,
      isDebug: false,
      appId: process.env.VUE_APP_APPS_FLYER_APP_ID,
      waitForATTUserAuthorization: 0,
    })
  }
}

function initRemoteConfig() {
  if (window.cordova) {
    const fetchTimeout = 60
    const minimumFetchInterval = 3600
    window.FirebasePlugin.setConfigSettings(fetchTimeout, minimumFetchInterval, () => {}, () => {})
    window.FirebasePlugin.fetchAndActivate(() => {}, () => {})
  } else {
    const remoteConfig = getRemoteConfig(store.state.tools.firebaseApp)
    remoteConfig.settings.minimumFetchIntervalMillis = 3600000
    fetchAndActivate(remoteConfig).then(() => {}).catch(() => {})
    store.dispatch('tools/setFirebaseRemoteConfig', remoteConfig)
  }
}

function configVue() {
  Vue.use(vueMoment, { moment })
  Vue.use(JsonViewer)
  Vue.use(VueDOMPurifyHTML)

  numeral.locale('en')
  numeral.localeData().delimiters.thousands = ' '
  numeral.fn._format = numeral.fn.format
  numeral.fn.format = function format(a, b) { return numeral.fn._format.call(this, a, b || numeral._.defaultRoundingFunction) }
  numeral._.defaultRoundingFunction = Math.floor
  Vue.filter('numeral', (value, format = '0,0') => numeral(value).format(format))
  Vue.filter('beautiful-minus', (value) => value.replace('-', '− '))
  Vue.filter('phone', (value) => (value && value.length === 12 ? value.replace(/[^0-9]/g, '').replace(/(\d{1})(\d{3})(\d{3})(\d{2})(\d{2})/, '+$1 ($2) $3-$4-$5') : value))

  Vue.directive('mask', VueMaskDirective)

  Vue.prototype.rules = rules

  Vue.prototype.$goBack = () => {
    window.history.length > 1 ? router.go(-1) : router.push('/kkm')
  }

  Vue.prototype.$openLink = (link, target) => {
    if (isDeepLink(link)) {
      return router.push(getDeepLinkRoute(link))
    }
    if (window.cordova !== undefined) {
      return window.cordova.InAppBrowser.open(link, target, 'hidenavigationbuttons=yes,hideurlbar=yes,zoom=no,toolbarcolor=#272727,closebuttoncolor=#ffffff')
    }
    return window.open(link, target)
  }

  Vue.prototype.$isCordova = () => window.cordova !== undefined

  Vue.prototype.$isAndroid = () => window.cordova !== undefined && window.device.platform === 'Android'

  Vue.prototype.$isFFBMode = () => process.env.VUE_APP_FFB_POS_MODE === 'true'
}

function initWebApp() {
  initWebFirebase()
  initWebFirebaseAuth()
  initWebHelpCrunch()

  const vue = new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: (h) => h(App),

  })
  configVue()

  initFirebaseAnalytics()
  initRemoteConfig()
  initFacebookPixel()

  vue.$mount('#app')
}

function initCordovaApp() {
  initCordovaHelpCrunch()

  const vue = new Vue({
    router,
    vuetify,
    store,
    i18n,
    render: (h) => h(App),
  })
  configVue()

  initFirebaseAnalytics()
  initAppsFlyer()
  initRemoteConfig()

  vue.$mount('#app')

  // Handling Android "Back button"
  document.addEventListener('backbutton', () => {
    if (router.history.current.path === '/' || router.history.current.path === '/kkm') {
      window.plugins.appMinimize.minimize()
    } else {
      router.go(-1)
    }
  }, false)

  // Handling deeplinks
  initDeepLinkHandling(router)

  // Hide scrollbar in iOS
  if (window.cordova !== undefined && window.device.platform === 'iOS') {
    window.plugins.hideScrollbar.hide(() => {})
  }
}

if (window.cordova) {
  document.addEventListener('deviceready', () => {
    initCordovaApp()
  })
} else {
  initWebApp()
}
